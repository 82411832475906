import React from 'react'

import store from '../store'

export const AdminPointUpdate = ({ pointId }) => {
  const { points } = store()
  const [pointTitle, setPointTitle] = React.useState(points.items[pointId].title)

  return (
    <input
      type="text"
      className="form-control form-control-sm"
      value={pointTitle}
      onChange={e => setPointTitle(e.target.value)}
    />
  )
}