import React from 'react'
import classNames from 'classnames'

import store from '../store'

export const AdminProductCreate = ({ listId }) => {
  const { productCreate } = store()

  const [productTitle, setProductTitle] = React.useState('')
  const [productByWeight, setProductByWeight] = React.useState('')
  const [productRound, setProductRound] = React.useState('')
  const [productMinimum, setProductMinimum] = React.useState('')
  const [productPrice, setProductPrice] = React.useState('')
  const [productRemains, setProductRemains] = React.useState('')
  const [productDays, setProductDays] = React.useState([])


  const productDaysToggle = (input) => {
    let days = [...productDays]
    if (days.includes(input)) {
      days = days.filter(d => d !== input)
      setProductDays(days)
    } else {
      days.push(input)
      setProductDays(days)
    }
  }

  const AdminProductCreateDays = () => {
    let week = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс']
    return (
      week.map((dayOfWeek, index) => {
        let thisDayChoosen = false
        for (let choosenDay in productDays) {
          if (productDays[choosenDay] === index + 1) {
            thisDayChoosen = true
          }
        }

        return (
          <button
            key={dayOfWeek}
            className={classNames('btn btn-xsm', { 'btn-outline-primary': thisDayChoosen === false, 'btn-primary': thisDayChoosen === true })}
            onClick={() => productDaysToggle(index + 1)}
          >
            {dayOfWeek}
          </button >
        )
      })
    )
  }


  return (
    <div className="table-responsive">
      <table className="table table-sm table-hover table-borderless w-100">
        <tbody>
          <tr>
            <td width="290"><input type="text" className="form-control form-control-sm" value={productTitle} placeholder="Новый товар" onChange={e => setProductTitle(e.target.value)} /></td>
            <td width="100"><input type="text" className="form-control form-control-sm" value={productByWeight} onChange={e => setProductByWeight(e.target.value)} /></td>
            <td width="100"><input type="text" className="form-control form-control-sm" value={productRound} onChange={e => setProductRound(e.target.value)} /></td>
            <td width="100"><input type="text" className="form-control form-control-sm" value={productMinimum} onChange={e => setProductMinimum(e.target.value)} /></td>
            <td width="100"><input type="text" className="form-control form-control-sm" value={productPrice} onChange={e => setProductPrice(e.target.value)} /></td>
            <td width="100"><input type="text" className="form-control form-control-sm" value={productRemains} onChange={e => setProductRemains(e.target.value)} /></td>
            <td><div className="btn-group"><AdminProductCreateDays /></div></td>
            <td width="100">
              <button
                disabled={!productTitle}
                className="btn btn-primary btn-sm"
                type="button"
                onClick={() => {
                  productCreate({
                    listId,
                    title: productTitle,
                    byweight: productByWeight,
                    minimum: productMinimum,
                    round: productRound,
                    price: productPrice,
                    remains: productRemains,
                    days: productDays,
                  });
                  setProductTitle('')
                }}
              >
                Добавить
              </button>
            </td>
          </tr>
        </tbody>
      </table >
    </div >
  )
}