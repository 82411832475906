import React from 'react'

import store from '../store'
import { AdminProductRow } from './AdminProductRow'
import { AdminProductCreate } from './AdminProductCreate'


export const AdminProducts = ({ listId }) => {
  const { lists } = store()

  const products = lists.items[listId].products

  const productsCheck = () => {
    if (products.ids.length > 0) {

      return (
        <div className="table-responsive">
          <table className="table table-sm table-borderless table-hover w-100">
            <thead>
              <tr>
                <th width="300">Наименование</th>
                <th width="100">Весовой товар</th>
                <th width="100">Кратность</th>
                <th width="100">Мин. заказ</th>
                <th width="100">Цена</th>
                <th width="100">Не учитывать в ост.</th>
                <th width="100">Доступность</th>
                <th width="100">Удалить</th>
              </tr>
            </thead>
            <tbody>
              {products.ids.map(i =>
                <AdminProductRow key={i} listId={listId} productId={i} />
              )}
            </tbody>
          </table>
        </div>
      )
    } else {
      return <p className="m-0">Нет товаров в этом листе</p>
    }
  }

  return (
    <div className="shadow p-4 my-3">
      {productsCheck()}
      <AdminProductCreate listId={listId} />
    </div>

  )
}