import React from 'react'

import store from '../store'

export const AdminPlanRowCreate = () => {
  const { pointCreate } = store()

  const [pointTitle, setPointTitle] = React.useState('')
  const [pointDay1, setPointDay1] = React.useState('')
  const [pointDay2, setPointDay2] = React.useState('')
  const [pointDay3, setPointDay3] = React.useState('')
  const [pointDay4, setPointDay4] = React.useState('')
  const [pointDay5, setPointDay5] = React.useState('')
  const [pointDay6, setPointDay6] = React.useState('')
  const [pointDay7, setPointDay7] = React.useState('')


  return (
    <div className="table-responsive">
      <table className="table table-sm table-hover table-borderless">
        <tbody>
          <tr>
            <td width="220"><input type="text" className="form-control form-control-sm" value={pointTitle} placeholder="Новое подразделение" onChange={e => setPointTitle(e.target.value)} /></td>
            <td width="100"><input type="text" className="form-control form-control-sm" value={pointDay1} onChange={e => setPointDay1(e.target.value)} /></td>
            <td width="100"><input type="text" className="form-control form-control-sm" value={pointDay2} onChange={e => setPointDay2(e.target.value)} /></td>
            <td width="100"><input type="text" className="form-control form-control-sm" value={pointDay3} onChange={e => setPointDay3(e.target.value)} /></td>
            <td width="100"><input type="text" className="form-control form-control-sm" value={pointDay4} onChange={e => setPointDay4(e.target.value)} /></td>
            <td width="100"><input type="text" className="form-control form-control-sm" value={pointDay5} onChange={e => setPointDay5(e.target.value)} /></td>
            <td width="100"><input type="text" className="form-control form-control-sm" value={pointDay6} onChange={e => setPointDay6(e.target.value)} /></td>
            <td width="100"><input type="text" className="form-control form-control-sm" value={pointDay7} onChange={e => setPointDay7(e.target.value)} /></td>
            <td width="100">
              <button
                disabled={!pointTitle}
                className="btn btn-primary btn-sm"
                type="button"
                onClick={() => {
                  pointCreate({
                    title: pointTitle,
                    day1: pointDay1,
                    day2: pointDay2,
                    day3: pointDay3,
                    day4: pointDay4,
                    day5: pointDay5,
                    day6: pointDay6,
                    day7: pointDay7
                  });
                  setPointTitle('')
                }}
              >
                Добавить
              </button>
            </td>
          </tr>
        </tbody>
      </table >
    </div >
  )
}