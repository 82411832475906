import React from 'react'

import './App.css'
import store from '../store'

import { UserPanel } from './UserPanel'
import { AdminPanel } from './AdminPanel'


export const AppWrap = () => {
  React.useEffect(() => { stateFetch() }, [])
  const { stateFetch, points } = store()

  if (Object.keys(points).length > 0) {
    return (
      <App />
    )
  } else {
    return <p>Загрузка данных...</p>
  }
}


export const App = () => {
  const { adminMode } = store()

  if (adminMode) {
    return <AdminPanel />
  } else {
    return <UserPanel />
  }

}
