import React from 'react'

import store from '../store'


export const AdminAuth = () => {
  const { adminModeToggle } = store()

  const [password, setPassword] = React.useState('')

  const checkPassword = (input) => {
    setPassword(input)
    if (input === '12345') {
      document.getElementById("AdminModal").classList.remove("show")
      document.getElementsByClassName("modal-backdrop")[0].remove()
      adminModeToggle(true)
      setPassword('')
    }
  }

  return (
    <input
      id="AdminModalInput"
      className="form-control"
      type="text"
      value={password}
      placeholder="•••••"
      onChange={e => checkPassword(e.target.value)}
    />
  )
}

