import React from 'react'
import classNames from 'classnames'
import store from '../store'

export const UsertProductsRow = ({ listId, productId }) => {
  const { lists, productChoosenUpdate, todayNumber } = store()

  let product = lists.items[listId].products.items[productId]

  const [storage, setStorage] = React.useState('')
  const [order, setOrder] = React.useState('')

  const checkStorage = (input) => {
    if (input) {
      input = parseFloat(input.replace(/,/, '.'))
      let storage = input
      productChoosenUpdate({ listId, productId, storage })
      setStorage(storage)
    }
  }

  const checkOrder = (input) => {
    if (input) {
      input = parseFloat(input.replace(/,/, '.'))
      let order = input
      if (product.round > 0) {
        order = Math.ceil(order / product.round) * product.round
      }

      if (order < product.minimum) {
        order = product.minimum
      }
      productChoosenUpdate({ listId, productId, order })
      setOrder(order)
    }
  }

  const checkDisabled = (input) => {
    if (input.includes(todayNumber)) { return false }
    else return true
  }

  return (
    <tr key={productId} className={classNames({ 'product-choosen': storage > 0 || order > 0 })}>
      <td className={classNames({ 'product-remains-warning': product.remains })}>{product.title}</td>
      <td>{product.unit}</td>
      <td>{product.round}</td>
      <td>{product.minimum}</td>
      <td>{product.price.toLocaleString()}</td>

      <td>
        <input type="text"
          className="form-control form-control-sm"
          value={storage}
          onChange={e => setStorage(e.target.value)}
          onBlur={e => checkStorage(e.target.value)}
        />
      </td>

      <td className={classNames({ 'product-remains-warning': product.remains })} >
        {storage > 0 & !product.remains ? storage * product.price : 0}
      </td>

      <td>
        <input
          type="text"
          className="form-control form-control-sm"
          value={order}
          onChange={e => setOrder(e.target.value)}
          onBlur={e => checkOrder(e.target.value)}
          disabled={checkDisabled(product.days)}
        />
      </td>

      <td>
        {order > 0 ? order * product.price : ''}
      </td>
    </tr>
  )
}