import React from 'react'

import store from '../store'

export const AdminPlanDay = ({ planId, planDayNum }) => {
  const { plans, planDaySet } = store()

  const [daySum = plans.items[planId][planDayNum], setDaySum] = React.useState()

  return (
    <td key={planDayNum}>
      <input
        className="form-control form-control-sm"
        type="text"
        value={daySum}
        onChange={e => setDaySum(e.target.value)}
        onBlur={e => planDaySet({ planId: planId, planDayNum: planDayNum, planDaySum: e.target.value })}
      />
    </td>
  )
}