import React from 'react'
import axios from 'axios'

import store from '../store'

import { AdminPlans } from './AdminPlans'
import { AdminLists } from './AdminLists'

export const AdminPanel = () => {
  const { adminModeToggle, points, plans, lists } = store()

  let storeToSave = { points, plans, lists }

  const adminOut = () => {
    axios.post('https://zeh.capucino.ru/post/postState.php', storeToSave)
      .then(function (response) {
        console.log(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })
    adminModeToggle(false)
  }


  return (
    <div className="container">
      <div className="row my-5">
        <div className="col-lg-8">
          <div className="h2">Администрирование</div>
        </div>
        <div className="col-lg-4 text-right">
          <button
            className="btn btn-text text-primary"
            onClick={() => adminOut()}
          >
            Сохранить и выйти
          </button>
        </div>
      </div>
      <div className="accordion" id="accordion">
        <div className="card">
          <div className="card-header" id="heading1">
            <button className="btn font-weight-bold w-100 text-left collapsed" type="button" data-toggle="collapse" data-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
              Подразделения и планы
              </button>
          </div>
          <div id="collapse1" className="collapse" aria-labelledby="heading1" data-parent="#accordion">
            <div className="card-body">
              <AdminPlans />
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header" id="heading2">
            <button className="btn font-weight-bold w-100 text-left collapsed" type="button" data-toggle="collapse" data-target="#collapse2" aria-expanded="true" aria-controls="collapse2">
              Листы с товарами
              </button>
          </div>
          <div id="collapse2" className="collapse" aria-labelledby="heading2" data-parent="#accordion">
            <div className="card-body">
              <AdminLists />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
