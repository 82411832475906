import React from 'react'

import store from '../store'
import { AdminPlanDay } from './AdminPlanDay'
import { AdminPointUpdate } from './AdminPointUpdate'

export const AdminPlanRows = () => {
  const { points, pointDelete } = store()
  const days = [1, 2, 3, 4, 5, 6, 7]

  return (
    <>
      {points.ids.map(i =>
        <tr key={i}>
          <td><AdminPointUpdate pointId={i} /></td>
          {days.map(day => <AdminPlanDay key={day} planId={i} planDayNum={day} />)}
          <td><button className="btn btn-outline btn-sm text-danger" type="button" onClick={() => pointDelete(i)}>Удалить</button></td>
        </tr>
      )}
    </>
  )
}