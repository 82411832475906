import React from 'react'

import store from '../store'
import { AdminPlanRows } from './AdminPlanRows'
import { AdminPlanRowCreate } from './AdminPlanRowCreate'


export const AdminPlans = () => {
  const { points } = store()

  const pointsCheck = () => {
    if (points.ids.length > 0) {
      return (
        <div className="table-responsive">
          <table className="table table-sm table-hover table-borderless">
            <thead>
              <tr>
                <th width="200">Подразделение</th>
                <th width="100">Пн</th>
                <th width="100">Вт</th>
                <th width="100">Ср</th>
                <th width="100">Чт</th>
                <th width="100">Пт</th>
                <th width="100">Сб</th>
                <th width="100">Вс</th>
                <th width="100">Удалить</th>
              </tr>
            </thead>
            <tbody>
              <AdminPlanRows />
            </tbody>
          </table >
        </div >
      )
    } else {
      return <p className="my-4">Нет подразделений</p>
    }
  }

  return (
    <div className="row">
      <div className="col-xl-9">
        {pointsCheck()}
        <AdminPlanRowCreate />
      </div>
    </div>
  )
}