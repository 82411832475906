import React from 'react'
import classNames from 'classnames'

import store from '../store'
import { PointSelect } from './PointSelect'
import { UsertProductsRow } from './UsertProductsRow'
import { AdminAuth } from './AdminAuth'

export const UserPanel = () => {
  const { lists, listSetIsActive, points, plans, tomorrowNumber, listSendToSite } = store()

  const today = () => {
    let today = new Date()
    return today.toLocaleDateString('ru-RU')
  }


  const pointCheck = () => {
    if (points.activeId !== 0) {
      let orderPlan = plans.items[points.activeId][tomorrowNumber] * 1.3
      let orderTotal = lists.items[2].orderAll
      let storageTotal = lists.items[1].storageAll + lists.items[2].storageAll
      let orderMore = orderPlan - orderTotal - storageTotal
      let storageTotalId3 = lists.items[3].storageAll
      let orderTotalId3 = lists.items[3].orderAll

      const orderMoreCheck = () => {
        if (orderMore > 0) {
          return (
            <>
              нужно заказать еще на <span className="text-bold">{orderMore.toLocaleString()} ₽</span>
            </>
          )
        } else {
          return (
            <>
              план заявки перевыполнен на <span className="text-bold">{Math.abs(orderMore).toLocaleString()} ₽</span>
            </>
          )
        }

      }

      const listSendCheck = (i) => {
        let now = new Date()
        let hourNumber = now.getHours()

        let disabled = false
        let reason = ''
        let reasonAll = ''

        if (i === 1 & !lists.items[1].products.storageIds.length > 0 & !lists.items[1].products.orderIds.length > 0) {
          disabled = true
          reason = 'не указаны ни Остатки, ни Заказ'
        }

        if (i === 2 & !lists.items[2].products.storageIds.length > 0 & !lists.items[1].products.orderIds.length > 0) {
          disabled = true
          reason = 'не указаны ни Остатки, ни Заказ'
        }

        if (i === 1 & hourNumber > 10) {
          disabled = true
          reason = 'уже больше 11:00'
        }

        if (i === 2 & hourNumber < 15) {
          disabled = true
          reason = 'еще рано'
        }

        if (i === 2 & hourNumber > 19) {
          disabled = true
          reason = 'уже поздно'
        }

        if (i === 2 & orderMore > 0) {
          disabled = true
          reason = 'Заказ меньше Плана заявки'
        }

        if (i === 3 & !storageTotalId3 > 0 & i === 3 & !orderTotalId3 > 0) {
          disabled = true
          reason = 'не указаны ни Остатки, ни Заказ'
        }

        if (reason) {
          reasonAll = 'Невозможно отправить: ' + reason
        }

        return (
          <>
            <button className="btn btn-primary mr-3" disabled={disabled} onClick={() => listSendToSite(i)}>{lists.items[i].title} &rarr; Отправить</button>
            <div className="text-small text-muted">{reasonAll}</div>
          </>
        )
      }

      return (
        <div className="row">
          <div className="col-12">
            <div className="callout callout-primary my-5">
              <p>
                Красным цветом выделено название товаров, сумма которых не учитывается в столбике Остатке. <br />
                Если ячейка в столбце Заказ заблокирована, значит этот товар нельзя заказать сегодня.</p>

              <p>
                Корректировку можно отправить до 11:00<br />
                Заказ можно отправить с 15:00 до 17:00 (временно до 20:00)
              </p>
            </div>
          </div>
          <div className="col-12">
            <div className={classNames('alert p-2 pb-5 p-lg-5 mb-5', { 'alert-warning': orderMore > 0, 'alert-success': orderMore < orderPlan })}>
              <div className="row">
                <div className="col-lg-5">
                  <table className="table table-sm table-bordered plan-days">
                    <thead>
                      <tr>
                        <td>День недели</td>
                        <td>План продажи на завтра</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className={classNames({ 'plan-day': tomorrowNumber === 1 })}>
                        <td>Понедельник</td>
                        <td>{plans.items[points.activeId][1].toLocaleString()} ₽</td>
                      </tr>
                      <tr className={classNames({ 'plan-day': tomorrowNumber === 2 })}>
                        <td>Вторник</td>
                        <td>{plans.items[points.activeId][2].toLocaleString()} ₽</td>
                      </tr>
                      <tr className={classNames({ 'plan-day': tomorrowNumber === 3 })}>
                        <td>Среда</td>
                        <td>{plans.items[points.activeId][3].toLocaleString()} ₽</td>
                      </tr>
                      <tr className={classNames({ 'plan-day': tomorrowNumber === 4 })}>
                        <td>Четверг</td>
                        <td>{plans.items[points.activeId][4].toLocaleString()} ₽</td>
                      </tr>
                      <tr className={classNames({ 'plan-day': tomorrowNumber === 5 })}>
                        <td>Пятница</td>
                        <td>{plans.items[points.activeId][5].toLocaleString()} ₽</td>
                      </tr>
                      <tr className={classNames({ 'plan-day': tomorrowNumber === 6 })}>
                        <td>Суббота</td>
                        <td>{plans.items[points.activeId][6].toLocaleString()} ₽</td>
                      </tr>
                      <tr className={classNames({ 'plan-day': tomorrowNumber === 7 })}>
                        <td>Воскресенье</td>
                        <td>{plans.items[points.activeId][7].toLocaleString()} ₽</td>
                      </tr>
                    </tbody>
                  </table>

                </div>
                <div className="col-lg-6 offset-1 mt-5 mt-lg-0">
                  <div className="d-flex flex-wrap align-items-center">
                    <div className="h1 mr-4 lh-1">{orderPlan.toLocaleString()}&nbsp;₽</div>
                    <div>
                      <div className="text-bold">План заявки</div>
                      <div className="text-muted lh-1">130% плана продажи</div>
                    </div>
                  </div>
                  <hr />
                  <div className="d-flex flex-wrap align-items-center mt-4">
                    <div className="h1 mr-4 lh-1">{storageTotal.toLocaleString()}&nbsp;₽</div>
                    <div>
                      <div className="text-bold">Ваши остатки</div>
                      <div className="text-muted lh-1">сумма остатков из Корректировки и Заказа</div>
                    </div>
                  </div>
                  <div className="d-flex flex-wrap align-items-center mt-4">
                    <div className="h1 mr-4 lh-1">{orderTotal.toLocaleString()}&nbsp;₽</div>
                    <div>
                      <div className="text-bold">Ваш заказ</div>
                      <div className="text-muted lh-1">{orderMoreCheck()}</div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <ul className="nav nav-tabs">
              {
                lists.ids.map(i =>
                  <li
                    key={i}
                    className="nav-item"
                  >
                    <div>
                      <a
                        className={lists.activeId === i ? 'active nav-link' : 'nav-link'}
                        href={'#list-' + i}
                        onClick={() => listSetIsActive(i)}
                      >
                        {lists.items[i].title}
                      </a>
                    </div>
                  </li>
                )
              }
            </ul>
          </div>
          <div className="col-12">
            <div className="tab-content" id="myTabContent">
              {
                lists.ids.map(i =>
                  <div
                    key={i}
                    className={lists.activeId === i ? 'tab-pane fade show active' : 'tab-pane fade show'}
                    id={'list-' + i}
                  >

                    <div className="d-flex align-items-center  mt-4">
                      {listSendCheck(i)}
                    </div>

                    <div className="table-responsive">
                      <table className="table table-sm table-bordered table-hover w-auto mt-4">
                        <thead>
                          <tr>
                            <th width="300" rowSpan="2">Наименование</th>
                            <th width="50" rowSpan="2">Ед. изм</th>
                            <th width="100" rowSpan="2">Кратность</th>
                            <th width="100" rowSpan="2">Мин. заказ</th>
                            <th width="100" rowSpan="2">Цена</th>
                            <th colSpan="2">Остаток</th>
                            <th colSpan="2">Заказ</th>
                          </tr>
                          <tr>
                            <th width="100">шт / кг</th>
                            <th width="100">руб.</th>
                            <th width="100">шт / кг</th>
                            <th width="100">руб.</th>
                          </tr>
                        </thead>
                        <tbody>
                          {lists.items[i].products.ids.map(p =>
                            <UsertProductsRow key={p} listId={i} productId={p} />
                          )}
                          <tr className="text-bold">
                            <td colSpan="5" align="right">Итого</td>
                            <td colSpan="2" align="right">Остаток: {lists.items[i].storageAll.toLocaleString()} ₽</td>
                            <td colSpan="2" align="right">Заказ: {lists.items[i].orderAll.toLocaleString()} ₽</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      )
    }
  }

  return (
    <div className="container">
      <div className="row mt-5">
        <div className="col-lg-8">
          <div className="h2">Заявка в цех</div>
          <div className="h5 d-flex align-items-center flex-wrap">
            <div className="d-flex align-items-center"><span className="d-none d-lg-inline mr-2">от</span><PointSelect /></div>
            <div className="mt-2 mt-lg-0 ml-lg-2">от {today()}</div>
          </div>
        </div>
        <div className="col-lg-4 text-lg-right">
          <button
            className="btn btn-text text-primary"
            data-toggle="modal"
            data-target="#AdminModal"
          >
            Администрирование
          </button>
          <div className="modal fade" id="AdminModal" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Введите пароль</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <AdminAuth />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {pointCheck()}
    </div >
  )
}
