import React from 'react'

import store from '../store'
import { AdminProductDays } from './AdminProductDays'

export const AdminProductRow = ({ listId, productId }) => {
  const { lists, productDelete, productUpdate } = store()

  let product = lists.items[listId].products.items[productId]

  const [productTitle, setProductTitle] = React.useState(product.title)
  const [productByWeight, setProductByWeight] = React.useState(product.byweight ? 'да' : '')
  const [productRound, setProductRound] = React.useState(product.round || 1)
  const [productMinimum, setProductMinimum] = React.useState(product.minimum || 1)
  const [productPrice, setProductPrice] = React.useState(product.price)
  const [productRemains, setProductRemains] = React.useState(product.remains ? 'да' : '')

  const productUpdateCheck = () => {
    productUpdate({
      listId,
      productId,
      title: productTitle,
      byweight: productByWeight,
      round: productRound,
      minimum: productMinimum,
      price: productPrice,
      remains: productRemains,
      days: product.days
    })
  }

  return (
    <tr key={productId}>
      <td><input type="text" className="form-control form-control-sm" value={productTitle} onChange={e => setProductTitle(e.target.value)} onBlur={() => productUpdateCheck()} /></td>
      <td><input type="text" className="form-control form-control-sm" value={productByWeight ? 'да' : ''} onChange={e => setProductByWeight(e.target.value)} onBlur={() => productUpdateCheck()} /></td>
      <td><input type="text" className="form-control form-control-sm" value={productRound} onChange={e => setProductRound(e.target.value)} onBlur={() => productUpdateCheck()} /></td>
      <td><input type="text" className="form-control form-control-sm" value={productMinimum} onChange={e => setProductMinimum(e.target.value)} onBlur={() => productUpdateCheck()} /></td>
      <td><input type="text" className="form-control form-control-sm" value={productPrice} onChange={e => setProductPrice(e.target.value)} onBlur={() => productUpdateCheck()} /></td>
      <td><input type="text" className="form-control form-control-sm" value={productRemains ? 'да' : ''} onChange={e => setProductRemains(e.target.value)} onBlur={() => productUpdateCheck()} /></td>
      <td><div className="btn-group"><AdminProductDays listId={listId} productId={productId} /></div></td>
      <td>{<button className="btn btn-text btn-small text-danger" onClick={() => productDelete({ listId, productId })} >Удалить</button>}</td>
    </tr>
  )
}