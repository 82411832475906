import React from 'react'
import classNames from 'classnames'
import store from '../store'


export const AdminProductDays = ({ listId, productId }) => {
  const { lists, productDaysUpdate } = store()

  let week = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс']
  let product = lists.items[listId].products.items[productId]
  let days = product.days

  return (
    week.map((dayOfWeek, index) => {
      let thisDayChoosen = false

      for (let choosenDay in days) {
        if (days[choosenDay] === index + 1) {
          thisDayChoosen = true
        }
      }

      return (
        <button
          key={dayOfWeek}
          className={classNames('btn btn-xsm', { 'btn-outline-primary': thisDayChoosen === false, 'btn-primary': thisDayChoosen === true })}
          onClick={() => productDaysUpdate({ listId, productId, day: index + 1 })}
        >
          {dayOfWeek}
        </button >
      )
    })
  )
}