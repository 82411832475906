import React from 'react'

import store from '../store'

export const AdminListCreate = () => {
  const { listCreate } = store()

  const [listTitle, setListTitle] = React.useState('')

  return (
    <form onSubmit={(e) => { e.preventDefault(); listCreate(listTitle); setListTitle('') }} >
      <div className="text-small text-muted mb-2">Добавить лист</div>
      <div className="input-group w-auto">
        <input
          type="text"
          className="form-control"
          placeholder="Название листа"
          value={listTitle}
          onChange={e => setListTitle(e.target.value)}
        />

        <div className="input-group-append">
          <button
            disabled={!listTitle}
            className="btn btn-primary"
            type="button"
            onClick={() => { listCreate(listTitle); setListTitle('') }}
          >
            Добавить
          </button>
        </div>
      </div>
    </form>
  )
}