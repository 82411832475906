import React from 'react'

import store from '../store'
import { AdminListCreate } from './AdminListCreate'
import { AdminProducts } from './AdminProducts'


const AdminShowProducts = ({ listId }) => {
  const { showProductsToggle } = store()
  const [showProducts, setShowProducts] = React.useState(false)
  return (
    <button className="btn btn-text btn-small text-primary ml-2"
      onClick={() => { setShowProducts(!showProducts); showProductsToggle({ listId: listId, showProducts: !showProducts }) }}
    >{showProducts ? 'Свернуть товары' : 'Показать товары'}
    </button>
  )
}

export const AdminLists = () => {
  const { lists, listDelete } = store()

  const listsCheck = () => {
    if (lists.ids.length > 0) {
      return (
        <ul className="main-list">
          {
            lists.ids.map(i =>
              <li
                key={i}
                className="main-list__item flex-column align-items-start"
              >
                <div>
                  {lists.items[i].title}
                  <span className="text-small text-muted"> (Товаров: {lists.items[i].products.ids.length})</span>
                  <AdminShowProducts listId={i} />
                  <button className="btn btn-text btn-small text-danger" onClick={() => listDelete(i)}>Удалить лист</button>
                </div>
                {lists.items[i].showProducts ? <AdminProducts listId={i} /> : ''}
              </li>
            )
          }
        </ul>
      )
    } else {
      return <p className="my-4">Нет листов</p>
    }
  }

  return (
    <div className="row">
      <div className="col-xl-9">
        {listsCheck()}
        <AdminListCreate />
      </div>
    </div>
  )
}