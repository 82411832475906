import create from 'zustand'
import { devtools } from 'zustand/middleware'

import axios from 'axios'

let today = new Date()
let todayNumber = today.getDay()

let todayForFetch = new Date()

let tomorrow = new Date()
tomorrow.setDate(today.getDate() + 1)
let tomorrowNumber = today.getDay()


const store = create(devtools(
  set => ({
    adminMode: false,
    todayNumber: todayNumber,
    tomorrowNumber: tomorrowNumber,
    points: {},
    plans: {},
    lists: {},
    // points: {
    //   ids: [1, 2, 3],
    //   activeId: 1,
    //   items: {
    //     1: { id: 1, title: 'Европа' },
    //     2: { id: 2, title: 'Жукова' },
    //     3: { id: 3, title: 'КапБар' },
    //   }
    // },

    // plans: {
    //   ids: [1, 2, 3],
    //   items: {
    //     1: { 1: 100000, 2: 200, 3: 300, 4: 400, 5: 500, 6: 600, 7: 700 },
    //     2: { 1: 1000, 2: 2000, 3: 3000, 4: 4000, 5: 5000, 6: 6000, 7: 7000 },
    //     3: { 1: 100, 2: 200, 3: 300, 4: 400, 5: 500, 6: 600, 7: 700 },
    //   }
    // },

    // lists: {
    //   ids: [1, 2, 3],
    //   activeId: 1,
    //   items: {
    //     1: {
    //       id: 1,
    //       title: 'Корректировка',
    //       showProducts: false,
    //       storageAll: 0,
    //       orderAll: 0,
    //       products: {
    //         ids: [1, 2, 3],
    //         storageIds: [],
    //         orderIds: [],
    //         items: {
    //           1: { id: 1, title: 'Кекс Ромовая баба', byweight: true, unit: 'кг', price: 100, round: 2, minimum: 6, days: [1, 2, 4] },
    //           2: { id: 2, title: 'Кростата Лимонная', unit: 'шт', price: 100, remains: true, days: [5] },
    //           3: { id: 3, title: 'Мармелад клубничный', unit: 'шт', price: 100, round: 9, days: [] },
    //         }
    //       }
    //     },
    //     2: {
    //       id: 2,
    //       title: 'Заказ',
    //       showProducts: false,
    //       storageAll: 0,
    //       orderAll: 0,
    //       products: {
    //         ids: [1, 2, 3],
    //         storageIds: [],
    //         orderIds: [],
    //         items: {
    //           1: { id: 1, title: 'Кекс Ромовая баба2', byweight: true, unit: 'шт', price: 100, round: 2, minimum: 6, days: [] },
    //           2: { id: 2, title: 'Кростата Лимонная2', unit: 'шт', price: 100, remains: true, days: [] },
    //           3: { id: 3, title: 'Мармелад клубничный2', unit: 'шт', price: 100, round: 9, days: [] },
    //         }
    //       }
    //     },
    //     3: {
    //       id: 3,
    //       title: 'Заморозка',
    //       showProducts: false,
    //       storageAll: 0,
    //       orderAll: 0,
    //       products: {
    //         ids: [],
    //         storageIds: [],
    //         orderIds: [],
    //         items: {}
    //       }
    //     },
    //   }
    // },

    stateFetch: () => set(state => {
      function getActualState(date) {
        axios.get('https://zeh.capucino.ru/state/' + date.toLocaleDateString('ru-RU') + '.json')
          .then(function (response) {
            //console.log('найден файл с датой - ' + date.toLocaleDateString('ru-RU'))
            if (response.data) {
              //console.log('обновляю стейт')
              set({
                points: response.data.points,
                plans: response.data.plans,
                lists: response.data.lists
              })
            }
          })
          .catch(function (error) {
            //console.log('не найден файл с датой - ' + date.toLocaleDateString('ru-RU'))
            //console.log(error)
            todayForFetch.setDate(date.getDate() - 1)
            daysForFetchCount--

            if (daysForFetchCount > 0) {
              //console.log('новая дата - ' + todayForFetch.toLocaleDateString('ru-RU'))
              getActualState(todayForFetch)
            }
          })
          .then(function () {
            //console.log('get запрос завешен')
          })
      }
      getActualState(todayForFetch)
      let daysForFetchCount = 30
    }),

    adminModeToggle: (input) => set(state => {
      state.adminMode = input
    }),

    todayNumberSet: ({ todayNumber, tomorrowNumber }) => set(state => {
      state.todayNumber = todayNumber
      state.tomorrowNumber = tomorrowNumber
    }),

    showProductsToggle: ({ listId, showProducts }) => set(state => {
      state.lists.items[listId].showProducts = showProducts
    }),

    pointDelete: (id) => set(state => {
      state.points.ids = state.points.ids.filter(i => i !== id)
      delete state.points.items[id]
      state.plans.ids = state.plans.ids.filter(i => i !== id)
      delete state.plans.items[id]
    }),

    pointCreate: ({ title, day1, day2, day3, day4, day5, day6, day7 }) => set(state => {
      let id = 1
      if (Math.max(...state.points.ids) > 0) { id = Math.max(...state.points.ids) + 1 }
      state.points.ids.push(id)
      state.points.items[id] = { id, title }
      state.plans.ids.push(id)
      state.plans.items[id] = { 1: day1, 2: day2, 3: day3, 4: day4, 5: day5, 6: day6, 7: day7 }
    }),

    pointSetIsActive: (id) => set(state => {
      state.points.activeId = Number(id)
    }),

    listCreate: (title) => set(state => {
      let id = 1
      if (Math.max(...state.lists.ids) > 0) { id = Math.max(...state.lists.ids) + 1 }
      state.lists.ids.push(id)
      state.lists.items[id] = {
        id,
        title,
        storageAll: 0,
        orderAll: 0,
        products: {
          ids: [],
          storageIds: [],
          orderIds: [],
          items: {}
        }
      }
    }),

    listDelete: (id) => set(state => {
      state.lists.ids = state.lists.ids.filter(i => i !== id)
      delete state.lists.items[id]
    }),

    listSetIsActive: (id) => set(state => {
      state.lists.activeId = id
    }),

    listSendToSite: (id) => set(state => {
      console.log(state.lists.items[id].products.storageIds);
      console.log(state.lists.items[id].products.orderIds);
    }),

    planDaySet: ({ planId, planDayNum, planDaySum }) => set(state => {
      state.plans.items[planId][planDayNum] = planDaySum
    }),

    productCreate: ({ listId, title, byweight, minimum, round, price, remains, days }) => set(state => {
      remains = remains ? true : false
      byweight = byweight ? true : false
      let unit = 'шт'
      if (byweight) { unit = 'кг' }
      if (!days) { days = [] }
      if (!round) { round = 1 }
      if (!minimum) { minimum = 1 }
      let id = 1
      let products = state.lists.items[listId].products
      if (Math.max(...products.ids) > 0) { id = Math.max(...products.ids) + 1 }
      products.ids.push(id)
      products.items[id] = { title, byweight, unit, round: Number(round), minimum: Number(minimum), price: Number(price), remains, days }
    }),

    productUpdate: ({ listId, productId, title, byweight, minimum, round, price, remains, days }) => set(state => {
      remains = remains ? true : false
      byweight = byweight ? true : false
      let unit = 'шт'
      if (byweight) {
        unit = 'кг'
      }
      state.lists.items[listId].products.items[productId] = { title, byweight, unit, round: Number(round), minimum: Number(minimum), price: Number(price), remains, days }
    }),

    productDelete: ({ listId, productId }) => set(state => {
      state.lists.items[listId].products.ids = state.lists.items[listId].products.ids.filter(i => i !== productId)
      delete state.lists.items[listId].products.items[productId]
    }),

    productDaysUpdate: ({ listId, productId, day }) => set(state => {
      let product = state.lists.items[listId].products.items[productId]

      if (product.days.includes(day)) {
        product.days = product.days.filter(d => d !== day)
      } else {
        product.days.push(day)
      }
    }),

    productChoosenUpdate: ({ listId, productId, storage, order }) => set(state => {
      storage = Number(storage)
      order = Number(order)

      let products = state.lists.items[listId].products

      // обработка остатков
      if (storage > 0) {
        products.items[productId].storage = storage

        if (!products.storageIds.includes(productId)) {
          products.storageIds.push(productId)
        }
      } else if (!isNaN(storage)) {
        products.items[productId].storage = storage
        if (products.storageIds.includes(productId)) {
          products.storageIds = products.storageIds.filter(i => i !== productId)
        }
      }

      let storageAll = 0
      if (products.storageIds.length > 0) {
        products.storageIds.map(i => {
          if (!products.items[i].remains) {
            let storageRub = Number(products.items[i].storage) * products.items[i].price
            storageAll = storageAll + storageRub
          }
          return storageAll
        })
      }

      state.lists.items[listId].storageAll = storageAll

      // обработка заказа
      if (order > 0) {
        products.items[productId].order = order
        if (!products.orderIds.includes(productId)) {
          products.orderIds.push(productId)
        }
      } else if (!isNaN(order)) {
        products.items[productId].order = order
        if (products.orderIds.includes(productId)) {
          products.orderIds = products.orderIds.filter(i => i !== productId)
        }
      }

      let orderAll = 0

      if (products.orderIds.length > 0) {
        products.orderIds.map(i => {
          let storageRub = Number(products.items[i].order) * products.items[i].price
          orderAll = orderAll + storageRub
          return orderAll
        })
      }

      state.lists.items[listId].orderAll = orderAll
    }),
  })
))

export default store