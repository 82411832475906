import React from 'react'

import store from '../store'


export const PointSelect = () => {
  const { points, pointSetIsActive } = store()

  if (points.ids.length > 0) {
    return (
      <select
        name=""
        id=""
        className="form-control w-auto"
        onChange={(e) => pointSetIsActive(e.target.value)}
        value={points.activeId}
      >
        <option value="0">Выберите подразделение</option>
        {points.ids.map(i =>
          <option value={i} key={i} >{points.items[i].title}</option>
        )}
      </select>
    )
  } else {
    return <p>Нет доступных подразделений</p>
  }
}